import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Title from "../components/title";
import Summary from "../components/summary";
import EventOutline from "../components/teaserEvents";
import VOD from "src/components/player/native";
import media from "src/styled/responsive";

const ContentWrapper = styled.div`
  margin: 2rem 0;

  ${media.md`
    margin: 0;
  `}

  ${media.xs`
    margin: 1rem 0;
  `}
`;

const StyledRow = styled(Row)`
  ${media.sm`
    flex-direction: column-reverse;
 `}
`;

const VODWrapper = styled.div`
  ${media.md`
    display: block;
    position: relative;
    height: 100%;
    min-height: 343px;
    margin: 0 0 1.5rem;
  `}

  ${media.xs`
    min-height: 200px;
  `}
`;

const IndexPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Title />
      <ContentWrapper>
        <Grid>
          <StyledRow center="sm" between="md">
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={6}>
              <Summary />
            </Col>
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={6}>
              <VODWrapper>
                <VOD />
              </VODWrapper>
            </Col>
          </StyledRow>
        </Grid>
      </ContentWrapper>
      <EventOutline />
    </Layout>
  );
};

export default IndexPage;
