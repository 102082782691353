import React from "react";
import { useIntl, navigate } from "gatsby-plugin-intl";
import Button from "./button";
import styled from "styled-components";
import media from "src/styled/responsive";
import download from "../images/icons/download-icon.svg";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  ${media.xs`
    justify-content: flex-start;
    margin: 0 0 1rem;
  `}
`;

const Details = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 32px;

  &:before {
    content: "";
    background-image: url(${download});
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
    background-size: contain;
    margin: 2px 12px 0 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 0.5rem 0 0;

  ${media.xs`
    min-width: 265px;
    margin: 0 0 1rem;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  ${media.xs`
    flex-flow: column;
  `}
`;

const Summary = () => {
  const intl = useIntl();
  return (
    <Wrapper>
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatHTMLMessage({ id: "teaser.home.description" }),
        }}
      />
      <Details>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatHTMLMessage({ id: "teaser.home.document" }),
          }}
        />
      </Details>
      <ButtonWrapper>
        <StyledButton
          skin="yellow"
          shape="polygon"
          onClick={() => navigate("/programme")}
        >
          {intl.formatMessage({ id: "teaser.home.fullprogramme" })}
        </StyledButton>
        <StyledButton
          skin="grey"
          shape="normal"
          onClick={() =>
            (window.location.href = intl.formatMessage({
              id: "teaser.home.practicalInfo.url",
            }))
          }
        >
          {intl.formatMessage({ id: "teaser.home.practicalInfo.title" })}
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Summary;
