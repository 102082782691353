import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { color } from "src/styled/variables";
import media from "src/styled/responsive";
import icon from "src/images/summit-icon.svg";

const { ilo_blue, ilo_yellow } = color;

const StyledSection = styled.div`
  background-color: ${ilo_blue};
  color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);

  ${media.md`
    padding: 2rem 0 0 0;
  `}

  ${media.xs`
    display: none;
  `}
`;

const TitleBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  img {
    top: 4.25rem;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
    position: relative;
    margin: 0 1.2rem 0 0;
  }
`;

const YellowBadge = styled.div`
  position: relative;
  padding: 3px 0px 3px 5px;

  &:before {
    position: absolute;
    top: 0.2625rem;
    left: -19px;
    width: 0;
    height: 0;
    content: "";
    border-top: 0.4rem solid transparent;
    border-bottom: 0.4rem solid transparent;
    border-left: 0.7rem solid ${ilo_yellow};
  }
`;

const WhiteBadge = styled.div`
  position: relative;
  padding: 3px 0px 3px 18px;

  &:before {
    position: absolute;
    top: 0.6625rem;
    left: 1px;
    width: 0;
    height: 0;
    content: "";
    border-top: 0.4rem solid transparent;
    border-bottom: 0.4rem solid transparent;
    border-left: 0.7rem solid #fff;
  }
`;

const DatesWrapper = styled.div`
  padding-top: 4rem;

  ${media.sm`
    padding-top: 2rem;
  `}
`;

const StyledRow = styled(Row)`
  margin-bottom: 3.625rem;

  ${media.sm`
    margin-bottom: 2rem;
  `}
`;

const EventOutline = () => {
  const intl = useIntl();
  return (
    <StyledSection>
      <Grid>
        <StyledRow between="lg">
          <Col
            sm={10}
            smOffset={1}
            mdOffset={0}
            md={6}
            style={{ paddingRight: "6rem" }}
          >
            <TitleBlock>
              <img src={icon} alt="" />
              <div>
                <h2 className="teaserEventSection">
                  {intl.formatMessage({ id: "teaser.home.events.title" })}
                </h2>
                <h3
                  className="teaserEventBrief"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatHTMLMessage({
                      id: "teaser.home.events.outline",
                    }),
                  }}
                />
              </div>
            </TitleBlock>
          </Col>
          <Col sm={2} md={1}>
            &nbsp;
          </Col>
          <Col sm={10} md={5}>
            <DatesWrapper>
              <WhiteBadge>
                <YellowBadge>
                  <h3 className="teaserEvent">
                    {intl.formatMessage({ id: "teaser.home.events.regional" })}
                  </h3>
                  <h4 className="teaserEvent">
                    {intl.formatMessage({
                      id: "teaser.home.events.regionaldate",
                    })}
                  </h4>
                </YellowBadge>
              </WhiteBadge>
              <br />
              <br />
              <WhiteBadge>
                <YellowBadge>
                  <h3 className="teaserEvent">
                    {intl.formatMessage({ id: "teaser.home.events.global" })}
                  </h3>
                  <h4 className="teaserEvent">
                    {intl.formatMessage({
                      id: "teaser.home.events.globaldate",
                    })}
                  </h4>
                </YellowBadge>
              </WhiteBadge>
            </DatesWrapper>
          </Col>
        </StyledRow>
      </Grid>
    </StyledSection>
  );
};

export default EventOutline;
